<script>
import AdminLayout from "@layouts/admin";
import FormFieldInput from "@components/form-field-input";
import moment from "moment"

export default {
    name: "NocDetail",
    components: {
        AdminLayout,
        FormFieldInput,
    },
    data() {
        return {
            noc: {},
            showModal: false,
            nocId: null,
        };
    },
    created() {
        this.nocId = this.$route?.params?.nocId;

        this.getData();
    },
    methods: {
        async getData() {
            try {
                const { noc } = await this.$http.getById(
                    "common/noc-detail",
                    this.nocId
                );
                this.noc = noc;
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
    },
    computed: {
        isValid() {
            if(this.noc) {
                const validTill = moment(this.noc.issueDate).add(4, 'years');
                const currentDate = moment();
                const difference = validTill.diff(currentDate, 'days');

                this.validTill = validTill

                if(difference) {
                    return true
                }
                return false
            }
            return false
        }
    }
};
</script>

<template lang="pug">
admin-layout
    .container.px-4.py-8.text-center.mx-auto
        h2.text-center.text-4xl.mb-8.res-h1.pb-4.border NO OBJECTION CERTIFICATE
        span.text-center
            p.text-green-600.text-3xl(v-if="isValid") Valid till {{ $filters.dateFormat(noc.expiryDate, "DD MMM, YYYY") }}
            p.text-red-600.text-3xl(v-else) Noc has been expired
        .grid.grid-rows-2.grid-flow-col.gap-4.res-col
            .row-span-3.ml-auto.res-row
                .flex.m-8
                    .text-2xl Name: {{ noc.name ? $filters.humanize(noc.name) : 'Na' }}
                .flex.m-8
                    .text-2xl Phone: {{ noc.phone }}
                .flex.m-8
                    .text-2xl Email: {{ noc.email }}
                .flex.m-8
                    .text-2xl State: {{ noc.state }}
                .flex.m-8
                    .text-2xl NOC Number: {{ noc.nocNumber }}
            .row-span-2.mr-auto.res-row
                .flex.m-8
                    .text-2xl Registration Type: {{ noc.type ? $filters.humanize(noc.type) : 'Na' }}
                .flex.m-8
                    .text-2xl Chassis Number: {{ noc.chassisNumber }}
                .flex.m-8
                    .text-2xl Motor Number: {{ noc.motorNumber }}
                .flex.m-8
                    .text-2xl Vehical Type: {{ noc.vehicleType ? $filters.humanize(noc.vehicleType) : 'Na' }}
                .flex.m-8
                    .text-2xl Issued Date: {{  $filters.dateFormat(noc.issueDate, "DD MMM, YYYY") || 'Na'  }}
        .mx-auto.res-row.text-center
            .flex.m-8.justify-center(v-if="noc.createdBy")
                .text-2xl Created By: {{ $filters.humanize(noc.Dealer.shopName) || 'Na' }}
</template>
